import type { ComponentType } from "react"

// Learn more: https://www.framer.com/docs/guides/overrides/

export function withoutToast(Component): ComponentType {
    return (props) => {
        const copyEmail = () => {
            const email = "info@jannelle-brown.com"
            navigator.clipboard.writeText(email)
        }
        return <Component {...props} onTap={copyEmail} />
    }
}
